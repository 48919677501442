import {Component, inject, OnInit, signal} from '@angular/core';
import {Button} from "primeng/button";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-confirm-dialog',
  standalone: true,
  imports: [
    Button
  ],
  templateUrl: './confirm-dialog.component.html',
  styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent implements OnInit {
  message = signal('');
  private dynamicDialogConfig = inject(DynamicDialogConfig);
  private ref = inject(DynamicDialogRef);

  ngOnInit() {
    if (this.dynamicDialogConfig.data.confirmMessage) {
      this.message.set(this.dynamicDialogConfig.data.confirmMessage);
    }
  }

  confirm(confirmAction: boolean) {
    if (confirmAction) {
      this.ref.close(true);
    } else {
      this.ref.close(false);
    }
  }
}
